import {SiteStore} from '@wix/wixstores-client-storefront-sdk';
import {IWidgetControllerConfig} from '@wix/native-components-infra';
import {ILink} from '@wix/wixstores-client-core';
import {PaymentRequestState} from '../../../types/paymentRequestApp.types';

export class PaymentRequestNavigationService {
  private readonly siteStore: SiteStore;
  private readonly controllerConfig: IWidgetControllerConfig;
  private continueShoppingLink!: ILink;
  public paymentRequestState: PaymentRequestState = PaymentRequestState.unpaid;

  constructor({siteStore, controllerConfig}: {controllerConfig: IWidgetControllerConfig; siteStore: SiteStore}) {
    this.siteStore = siteStore;
    this.controllerConfig = controllerConfig;
  }

  public async load() {
    this.continueShoppingLink = await this.fetchContinueShoppingLink();
  }

  public setPaymentRequestState(state: PaymentRequestState) {
    this.paymentRequestState = state;
  }

  private async fetchContinueShoppingLink(): Promise<ILink> {
    return this.siteStore.getHomepageLink();
  }

  public get continueShoppingUrl() {
    return this.continueShoppingLink.url;
  }
}
