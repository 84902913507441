import {ambassadorWithHeaders} from '../../utils/ambassador.utils';
import {ControllerFlowAPI} from '@wix/yoshi-flow-editor';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk';
import {HttpError} from '@wix/http-client';
import {getOrderPaymentRequest} from '@wix/ambassador-ecom-v1-order-payment-request/http';
import {PaymentRequestModel} from '../../models/paymentRequest/PaymentRequest.model';
import {OrderPaymentRequest} from '@wix/ambassador-ecom-v1-order-payment-request/types';
import {FedopsInteractions} from '../../../components/PaymentRequest/constants';
import {PaymentRequestErrorCode} from '../../../types/paymentRequestApp.types';

export class PaymentRequestService {
  private readonly flowAPI: ControllerFlowAPI;
  private readonly siteStore: SiteStore;
  public readonly orderPaymentRequestId?: string;
  public paymentRequest!: PaymentRequestModel;
  public paymentRequestError?: PaymentRequestErrorCode;

  constructor({siteStore, flowAPI}: {flowAPI: ControllerFlowAPI; siteStore: SiteStore}) {
    this.siteStore = siteStore;
    this.flowAPI = flowAPI;
    this.orderPaymentRequestId = this.siteStore.location.path[1];
  }

  public async init(): Promise<void> {
    this.flowAPI.fedops.interactionStarted(FedopsInteractions.FetchPaymentRequest);
    await this.fetchPaymentRequest();
    this.flowAPI.fedops.interactionEnded(FedopsInteractions.FetchPaymentRequest);
  }

  public setPaymentRequestError(errorCode: PaymentRequestErrorCode) {
    this.paymentRequestError = errorCode;
  }

  public async fetchPaymentRequest(): Promise<void> {
    if (!this.orderPaymentRequestId) {
      console.error('No orderPaymentRequestId');
      this.setPaymentRequestError(PaymentRequestErrorCode.notFound);
    } else {
      try {
        const {data} = await ambassadorWithHeaders(
          getOrderPaymentRequest({
            orderPaymentRequestId: this.orderPaymentRequestId,
          }),
          this.siteStore,
          this.flowAPI
        );

        /* istanbul ignore next */
        if (data.orderPaymentRequest) {
          this.setPaymentRequest(data.orderPaymentRequest);
        } else {
          /* istanbul ignore next */
          console.error('No order payment request data from the API');
          /* istanbul ignore next */
          this.setPaymentRequestError(PaymentRequestErrorCode.notFound);
        }
      } catch (error) {
        const httpError = error as HttpError;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const errorDataDetails = httpError?.response?.data?.details;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const ErrorCode = errorDataDetails?.applicationError?.code as string;
        const code =
          ErrorCode === PaymentRequestErrorCode.notFound
            ? PaymentRequestErrorCode.notFound
            : PaymentRequestErrorCode.generalError;

        this.setPaymentRequestError(code);
      }
    }
  }

  private setPaymentRequest(paymentRequest: OrderPaymentRequest) {
    this.paymentRequest = new PaymentRequestModel(paymentRequest, {
      locale: this.flowAPI.controllerConfig.wixCodeApi.window.locale,
    });
  }
}
